import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({userType}) => {
  return (
    <>
      <div className="appBottomMenu">
        <Link to="/" className="item">
          <div className="col">
            <ion-icon name="pie-chart-outline"></ion-icon>
            <strong>Dashboard</strong>
          </div>
        </Link>
        <Link to="/transactions" className="item">
          <div className="col">
            <ion-icon name="document-text-outline"></ion-icon>
            <strong>Transaction</strong>
          </div>
        </Link>
        { userType && userType != 'RETAILER' ? (
        <Link to="/user-list" className="item">
          <div className="col">
            <ion-icon name="people-outline"></ion-icon>
            <strong>Users List</strong>
          </div>
        </Link>
        ) : ('') }        
        <Link to="/support" className="item">
          <div className="col">
          <ion-icon name="chatbubbles-outline"></ion-icon>
            <strong>Support</strong>
          </div>
        </Link>
        <Link to="/settings" className="item">
          <div className="col">
            <ion-icon name="settings-outline"></ion-icon>
            <strong>Settings</strong>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Footer;
