import React, { useState,useRef } from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login(){
    
    // const [userId,setUserId] = useState('RTU17433');
    // const [password,setPassword] = useState('Dinesh#95');
    // const [pin,setPin] = useState('3366');

    // const [userId,setUserId] = useState('DTFE16665');
    // const [password,setPassword] = useState('Barman2506@');
    // const [pin,setPin] = useState('2890');

    const [userId,setUserId] = useState('');
    const [password,setPassword] = useState('');
    const [pin,setPin] = useState('');

    const [toastClass,setToastClass] = useState('');
    const [toastShow,setToastShow] = useState('');
    const [toastMsg,setToastMsg] = useState('');
    const [loginVerify,setLoginVerify] = useState('');

    const navigate = useNavigate();
    const inputElement = useRef();
    

    const userLoginVerify = async (submitType) => {
        const ltln = '28.66977890,+77.34391700';
        const jsonBody = submitType === 'pinVerify' ? {
          ltln,
          userid: userId,
          password,
          pin,
        } : {
          ltln,
          userid: userId,
          password,
          login: 'web',
        };
        const endUrl = submitType === 'pinVerify'
          ? 'https://api.diosm.co.in/api/users/verifyPin'
          : 'https://api.diosm.co.in/api/users/login';
    
        try {
          const response = await fetch(endUrl, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(jsonBody),
          });
    
          if (!response.ok) {
            throw new Error('Failed to login verification');
          }
    
          const res = await response.json();
    
          if (res.ERROR_CODE === '00') {
            if (submitType === 'pinVerify') {
              setPin('');
              localStorage.setItem('token', res.TOKEN);
              localStorage.setItem('userType', res.USER_TYPE);
              localStorage.setItem('displayName', res.PROFILE_NAME);
              localStorage.setItem('userId', res.LOGIN_ID);
              navigate('/');
            } else {
              setLoginVerify(true);
            }
            setToastMsg(res.MESSAGE);
            setToastClass('bg-success');
            setToastShow(true);
          } else {
            setToastMsg(res.MESSAGE);
            setToastClass('bg-danger');
            setToastShow(true);
            setLoginVerify(false);
            if (submitType === 'pinVerify') {
              setLoginVerify(true);
              setPin('');
            }
          }
    
          setTimeout(() => setToastShow(false), 3000);
        } catch (error) {
          console.error('Error login :', error);
        }
      };
  
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'userId') setUserId(value);
        if (name === 'password') setPassword(value);
        if (name === 'pin') setPin(value);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        if (!userId.trim()) {
          setToastMsg('Please enter your userid');
          setToastClass('bg-danger');
          setToastShow(true);
          setTimeout(() => setToastShow(false), 3000);
          return;
        }
        if (!password.trim()) {
          setToastMsg('Please enter your password');
          setToastClass('bg-danger');
          setToastShow(true);
          setTimeout(() => setToastShow(false), 3000);
          return;
        }
    
        userLoginVerify(loginVerify ? 'pinVerify' : 'loginVerify');
      };
    
      const handleCloseToast = () => setToastShow(false);
      
      useEffect(()=>{
        if(loginVerify && inputElement.current){
          inputElement.current.focus();
        }
      });

        return (
            <>
                {loginVerify ?
                    (<div id="appCapsule">

                        <div className="section mt-2 text-center">
                            <h1>Enter Login Pin</h1>
                            <h4>Enter 4 digit login pin</h4>
                        </div>
                        <div className="section mb-5 p-2">
                            <form >
                                <div className="form-group basic">
                                    <input type="password" ref={inputElement} className="form-control verification-input" id="smscode" placeholder="••••" maxLength="4" onChange={handleInputChange} name="pin" value={pin} />
                                </div>

                                <div className="form-button-group transparent">
                                    <button type="button" onClick={handleSubmit}  className="btn btn-primary btn-block btn-lg">Verify</button>
                                </div>

                            </form>
                        </div>

                        <div id="toast-16" className={`toast-box toast-top ${toastShow ? 'show ' + toastClass : ''}`} style={{ 'top': toastShow ? '0px' : undefined }}>
                            <div className="in">
                                <div className="text">
                                    {toastMsg}
                                </div>
                            </div>
                            <button type="button" onClick={handleCloseToast} className="btn btn-sm btn-text-light close-button">OK</button>
                        </div>

                    </div>)
                    :

                    (<div id="appCapsule">

                        <div className="section mt-2 text-center">
                            <h1>Log in</h1>
                            <h4>Fill the form to log in</h4>
                        </div>
                        <div className="section mb-5 p-2">

                            <form >
                                <div className="card">
                                    <div className="card-body pb-1">
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >User Id</label>
                                                <input type="email" className="form-control" onChange={handleInputChange} name="userId" value={userId} placeholder="Your UserId" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" >Password</label>
                                                <input type="password" className="form-control" onChange={handleInputChange} name="password" value={password} placeholder="Your password" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle"></ion-icon>
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="form-links mt-2">
                                    <div></div>
                                    <div><a href="#" className="text-muted">Forgot Password?</a></div>
                                </div>

                                <div className="form-button-group  transparent">
                                    <button type="button" onClick={handleSubmit} className="btn btn-primary btn-block btn-lg">Log in</button>
                                </div>

                            </form>
                        </div>
 
                        <div id="toast-16" className={`toast-box toast-top ${toastShow ? 'show ' + toastClass : ''}`} style={{ 'top': toastShow ? '0px' : undefined }}>
                            <div className="in">
                                <div className="text">
                                    {toastMsg}
                                </div>
                            </div>
                            <button type="button" onClick={handleCloseToast} className="btn btn-sm btn-text-light close-button">OK</button>
                        </div>
                    </div>)
                }
            </>
        )

}

export default Login;