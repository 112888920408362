import { Modal } from 'bootstrap'; // Ensure Bootstrap Modal is imported

export const openModal = (modalId) => {
  const modalElement = document.getElementById(modalId);
  if (modalElement) {
    const modal = new Modal(modalElement);
    modal.show();
  } else {
    console.error(`Modal with ID ${modalId} not found.`);
  }
};

export const closeModal = (modalId) => {
  const modalElement = document.getElementById(modalId);
  const modal = Modal.getInstance(modalElement);
  if (modal) {
    modal.hide();
  } else {
    console.error(`Modal with ID ${modalId} is not initialized.`);
  }
};

export const closeModalAll = () => {
  const modals = document.querySelectorAll('.modal');
  modals.forEach(modalElement => {
    const modal = Modal.getInstance(modalElement);
    if (modal) {
      modal.hide();
    }
  });
  // Remove backdrop manually
  const backdrop = document.querySelector('.modal-backdrop');
  if (backdrop) {
    backdrop.remove();
  }
};
