import React from 'react'
import { Link } from 'react-router-dom';

export default function WalletCardFotter({userType=null}) {
    const footerItemsRT = [
        {
            heading: "Withdraw",
            icon: "arrow-down-outline",
            className: "icon-wrapper bg-danger",
            targetPath: "/"
        },
        {
            heading: "Send",
            icon: "arrow-forward-outline",
            className: "icon-wrapper",
            targetPath: "/transactions"
        },
        {
            heading: "Cards",
            icon: "card-outline",
            className: "icon-wrapper bg-success",
            targetPath: "/user-list"
        }
    ];
    const items = (userType == 'RETAILER') ? footerItemsRT : [];
   // const items = footerItemsRT;
  return (
    <div className="wallet-footer">
    {
    items.map((item,index) => {
            return (
                <div className="item">
                    <Link to={item.targetPath} >
                        <div className={item.className}>
                            <ion-icon name={item.icon}></ion-icon>
                        </div>
                        <strong>{item.heading}</strong>
                    </Link>
                </div>
            );
        })
    }  
    
       
        {/* <div className="item">
            <a href="#" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                <div className="icon-wrapper bg-danger">
                    <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <strong>Withdraw</strong>
            </a>
        </div>
        <div className="item">
            <a href="#" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                <div className="icon-wrapper">
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                </div>
                <strong>Send</strong>
            </a>
        </div>
        <div className="item">
            <a href="app-cards.html">
                <div className="icon-wrapper bg-success">
                    <ion-icon name="card-outline"></ion-icon>
                </div>
                <strong>Cards</strong>
            </a>
        </div>
        <div className="item">
            <a href="#" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                <div className="icon-wrapper bg-warning">
                    <ion-icon name="swap-vertical"></ion-icon>
                </div>
                <strong>Exchange</strong>
            </a>
        </div> */}
    </div>
  )
}
