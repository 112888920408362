import React, { useState } from 'react';

export default function AutoSuggestInput({ className, placeholder, list, onChange = () => {}, name, value }) {
  const [suggestions, setSuggestions] = useState([]);
  //const [inputValue, setInputValue] = useState('');

  const handleChange = (event) => {
    const value = event.target.value;
 
    if (value) {
      const filteredList = list.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredList);
    } else {
      setSuggestions([]);
    }
    onChange({name:value});
  };

  const handleSuggestionClick = (item) => {
    //setInputValue(item.name);
    //setInputUserId(item.DT_RowId);
    onChange(item);
    setSuggestions([]);
  };

  return (
    <div>
      <input
        className={className}
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {suggestions.length > 0 && (
        <ul style={{ border: '1px solid #ccc', padding: '0', margin: '0', listStyle: 'none' }}>
          {suggestions.map((item, index) => (
            <li
              key={item.DT_RowId} // Use a unique key for each item
              onClick={() => handleSuggestionClick(item)} // Pass the entire item
              style={{ cursor: 'pointer', padding: '8px', backgroundColor: '#fff' }}
            >
              {item.name} {/* Display the name of the suggestion */}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
