import React, { useState,useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import LayoutContext from '../contexts/layoutContext';
import { openModal, closeModalAll } from '../utility/modalUtils';

export default function Setting() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPin, setOldPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const layoutCotext = useContext(LayoutContext);
  const modalClosePass = useRef(null); // Create a ref for the modal

  const resetAllStates = () => {
    setOldPassword(''); setNewPassword(''); setConfirmPassword('');
    setOldPin(''); setNewPin(''); setConfirmPin('');
    setErrorMessage('');
  }

  // Password validation function
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };
  // Password Update Call API
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    
    if (!validatePassword(newPassword)) {
      setErrorMessage('Password must contain at least 1 capital letter, 1 number, 1 special character, and be at least 8 characters long.');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords don't match.");
      return;
    }
    if (oldPassword === newPassword) {
      setErrorMessage("The old password and new password should not be the same.");
      return;
    }

    const ltln = '28.66977890,+77.34391700';
    const jsonBody = {
      ltln,
      oldPassword,
      newPassword
    };
    const endUrl = "https://api.diosm.co.in/api/users/updatePassword";
    try {
      const response = await fetch(endUrl,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type' : 'application/json',
          'token': token,
        },
        body: JSON.stringify(jsonBody),
      });
      if(!response.ok){
        throw new Error('Failed to update password.');
      }
      const res = await response.json();
      if(res.ERROR_CODE === "00"){
        layoutCotext.setToastMsg(res.MESSAGE);
        layoutCotext.setToastClass('bg-success');
        layoutCotext.setToastShow('toast-16');

        if (modalClosePass.current) {
          modalClosePass.current.click();
        }
      } else {
        const message = res.MESSAGE ? res.MESSAGE : "Some thing went worng, Please retry.";
        setErrorMessage(message);
        return;
      }
    } catch (error){
      console.error('Error Password update :', error);
    }
  };

  const handlePinInput = (e, setState) => {
    const value = e.target.value;
    // Allow only numbers
    if (/^\d*$/.test(value) && value.length <= 6) {
      setState(value);
    }
  }

  const handlePinForm = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    
    if (oldPin < 6 || newPin < 6) {
      setErrorMessage('Pin must contain at least 4 digit numbers');
      return;
    }
    if (newPin !== confirmPin) {
      setErrorMessage("Pin don't match with Confirm Pin.");
      return;
    }
    if (oldPin === newPin) {
      setErrorMessage("The Old Pin and New Pin should not be the same.");
      return;
    }

    const ltln = '28.66977890,+77.34391700';
    const jsonBody = {
      ltln,
      oldPin,
      newPin
    };
    const endUrl = "https://api.diosm.co.in/api/users/updatePin";
    try {
      const response = await fetch(endUrl,{
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type' : 'application/json',
          'token': token,
        },
        body: JSON.stringify(jsonBody),
      });
      if(!response.ok){
        throw new Error('Failed to update password.');
      }
      const res = await response.json();
      if(res.ERROR_CODE === "00"){
        layoutCotext.setToastMsg(res.MESSAGE);
        layoutCotext.setToastClass('bg-success');
        layoutCotext.setToastShow('toast-16');

        if (modalClosePass.current) {
          modalClosePass.current.click();
        }
      } else {
        const message = res.MESSAGE ? res.MESSAGE : "Some thing went worng, Please retry.";
        setErrorMessage(message);
        return;
      }
    } catch (error){
      console.error('Error Password update :', error);
    } 
  }

  const handleOpenModal = (modalId) => {
    openModal(modalId);
    resetAllStates();
  };

  return (
    <div>
      <div className="listview-title mt-1">Security</div>
      <ul className="listview image-listview text mb-2 inset">
        <li>
          <a href="#" className="item" onClick={() => handleOpenModal('updatePasswordForm')}>
            <div className="in">
              <div>Update Password</div>
            </div>
          </a>
        </li>
        <li>
          <a href="#" className="item" onClick={() => handleOpenModal('updatePinForm')}>
            <div className="in">
              <div>Update Login Pin</div>
            </div>
          </a>
        </li>
        <li>
          <div className="item">
            <div className="in">
              <div>2 Step Verification</div>
              <div className="form-check form-switch ms-2">
                <input className="form-check-input" type="checkbox" id="SwitchCheckDefault3" defaultChecked={true} />
                <label className="form-check-label" htmlFor="SwitchCheckDefault3"></label>
              </div>
            </div>
          </div>
        </li>
        <li>
          <Link to="/logout" className="item">
            <div className="in">
              <div>Log out</div>
            </div>
          </Link>
        </li>
      </ul>

      <div className="modal fade action-sheet" id="updatePasswordForm" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{'display': 'flex'}}>
              <h5 className="modal-title">Update Password</h5>
              <a href="#" onClick={closeModalAll} ref={modalClosePass}>Close</a>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content">
                <form onSubmit={handleSubmit}>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="oldPassword">Old Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Old Password"
                      />
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="newPassword">New Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                      />
                    </div>
                  </div>

                  <div className="form-group basic">
                    <label className="label">Confirm Password</label>
                    <div className="input-group mb-2">
                      <input
                        type="password"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>

                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                  <div className="form-group basic">
                    <button type="submit" className="btn btn-primary btn-block btn-lg">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal fade action-sheet" id="updatePinForm" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{'display': 'flex'}}>
              <h5 className="modal-title">Update Pin</h5>
              <a href="#" data-bs-dismiss="modal" ref={modalClosePass}>Close</a>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content">
                <form onSubmit={handlePinForm}>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="oldPin">Old Pin</label>
                      <input
                        type="password"
                        className="form-control"
                        id="oldPin"
                        value={oldPin}
                        onChange={(e) => handlePinInput(e, setOldPin)}
                        placeholder="Old Pin"
                      />
                    </div>
                  </div>

                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label" htmlFor="newPin">New Pin</label>
                      <input
                        type="password"
                        className="form-control"
                        id="newPassword"
                        value={newPin}
                        onChange={(e) => handlePinInput(e, setNewPin)}
                        placeholder="New Pin"
                      />
                    </div>
                  </div>

                  <div className="form-group basic">
                    <label className="label">Confirm Pin</label>
                    <div className="input-group mb-2">
                      <input
                        type="password"
                        className="form-control"
                        value={confirmPin}
                        onChange={(e) => handlePinInput(e, setConfirmPin)}
                        placeholder="Confirm Pin"
                      />
                    </div>
                  </div>

                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

                  <div className="form-group basic">
                    <button type="submit" className="btn btn-primary btn-block btn-lg">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
