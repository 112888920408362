import React, {useContext} from 'react'

export default function WalletCard({children,layoutCotext={}}) {
    return (
        <div className="section wallet-card-section pt-1">
            <div className="wallet-card">
                <div className="balance">
                    <div className="left">
                        <span className="title">Total Balance</span>
                        <h1 className="total">₹ {(layoutCotext.profileInfo.totalBalance)}</h1>
                    </div>
                    <div className="right">
                        <a href="#" className="button" data-bs-toggle="modal" data-bs-target="#ModalBasic">
                            <ion-icon name="add-outline"></ion-icon>
                        </a>
                    </div>
                </div>
                
                {children}
            </div>
        </div>
    )
}
