import React, { useState, useEffect, useRef } from 'react'
import { json } from 'react-router-dom'

export default function StatBox({ getDashboardData, dashboardData = {}, setDashboardData, userType }) {
    const [statFor, setStatFor] = useState('ALL');
    //console.log(dashboardData);
    const jsonBody = {
        ltln: '28.66977890,+77.34391700',
        statFor: statFor
    };
    let endUrl;
    if(userType == 'DISTRIBUTOR' || userType == 'MDISTRIBUTOR'){
        endUrl = "https://api.diosm.co.in/api/users/distributor/dashboard";
    } else {
        endUrl = "https://api.diosm.co.in/api/users/retailer/dashboard";
    }
    

    const isMounted2 = useRef(false);
    useEffect(() => {
        if (isMounted2.current) return;
        if (getDashboardData && statFor) {
            const dd = localStorage.getItem('dashboardData');
            if (!dd) {
                getDashboardData(endUrl, jsonBody);
            } else {
                setDashboardData(JSON.parse(dd));
            }
        }
        isMounted2.current = true;
        setStatFor(false);
        console.log(statFor);
    }, [statFor, getDashboardData]);
    const refreshIconStyle = {
        'float': 'right',
        'marginTop': '-12px',
        'marginRight': '-12px',
        'fontSize': '20px',
        'cursor': 'pointer'
    };

    const refreshStat = (stat) =>{
        isMounted2.current = false;
        localStorage.removeItem('dashboardData');
        setStatFor(stat);
    }
    
    return (

        <div className="section">
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="stat-box">
                       <div onClick={() => refreshStat('todayTransaction')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                        <div className="title">Today Trans.</div>
                        <div className="value text-success">₹ {(dashboardData.todayTransaction > 0) ? dashboardData.todayTransaction : '0.00'}</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="stat-box">
                       <div onClick={() => refreshStat('monthlyTotalTrans')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                        <div className="title">This Month Trans.</div>
                        <div className="value text-dark">₹ {(dashboardData.monthlyTotalTrans > 0) ? dashboardData.monthlyTotalTrans : '0.00'}</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="stat-box">
                       <div onClick={() => refreshStat('todayCommi')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                        <div className="title">Today Commission</div>
                        <div className="value text-danger">₹ {(dashboardData.todayCommi > 0) ? dashboardData.todayCommi : '0.00'}</div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="stat-box">
                       <div onClick={() => refreshStat('thisMonthCommi')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                        <div className="title">This Month Commi.</div>
                        <div className="value text-primary">₹ {(dashboardData.thisMonthCommi > 0) ? dashboardData.thisMonthCommi : '0.00'}</div>
                    </div>
                </div>
                { dashboardData.todayActiveRetailer ? (
                <div className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                    <div className="stat-box">
                       <div onClick={() => refreshStat('todayTransaction')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                        <div className="title">Active Retailers.</div>
                        <div className="value text-success"> {(dashboardData.todayActiveRetailer > 0) ? dashboardData.todayActiveRetailer : '0'}</div>
                    </div>
                </div>
                ) :('')}
                {
                dashboardData.totalUsers && dashboardData.totalUsers.length > 0 ? (
                    dashboardData.totalUsers.map((user, index) => (
                        <React.Fragment key={index}>
                        <div key={ 'A' + index} className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                            <div className="stat-box">
                            <div onClick={() => refreshStat('totalUsers')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                                <div className="title">Total {user.usertype}</div>
                                <div className="value text-warning">{Math.round(user.userCount)}</div>
                            </div>
                        </div>
                        
                        <div key={ 'B'+ index} className="col-lg-4 col-md-6 col-sm-6 col-6 mt-2">
                            <div className="stat-box">
                                <div onClick={() => refreshStat('totalUsers')} style={refreshIconStyle}><ion-icon name="refresh" ></ion-icon></div>
                                <div className="title">{user.usertype} Bal.</div>
                                <div className="value text-info">₹ {Math.round(user.totalBalance)}</div>
                            </div>
                        </div>
                        </React.Fragment>
                    ))
                ) : (
                    ''
                )}
                
                <div className="col-lg-9 col-md-8 col-sm-8 col-8 mt-2">
                    <div className="stat-box text-center">                                             
                        <div style={{'cursor': 'pointer'}} onClick={() => refreshStat('ALL')}  className="value text-success text-sm"><small>Refresh All <ion-icon name="refresh" ></ion-icon></small></div>
                    </div>
                </div>

            </div>

        </div>
    )
}
