import React, {  useContext, useEffect, useState,useRef } from 'react'
import { useNavigate } from "react-router-dom";
import WalletCard from '../components/dashboard/WalletCard';
import WalletCardFotter from '../components/dashboard/WalletCardFotter';
import StatBox from '../components/dashboard/StatBox';
import LayoutContext from '../contexts/layoutContext';
import { openModal, closeModalAll } from '../utility/modalUtils';

const Home = () => {        
        //const {token,profileInfo,toastClass,toastShow,toastMsg,setProfileInfo} = useContext(LayoutContext);
        const layoutCotext = useContext(LayoutContext);
        const [dashboardData, setDashboardData] = useState({});
        const [appFooterStyle, setAppFooterStyle] = useState({});
        const navigate = useNavigate();
const userDetails = {}; // temparory use in modal popup
        
        const isMounted = useRef(false);
        useEffect(() => {
            closeModalAll(); // Close all modal popup if open
            if (isMounted.current) return;
            const token = localStorage.getItem('token');
            if(token){
                fetchUserProfile();                
            } else {
              localStorage.clear();
               navigate('/login');
            }
            isMounted.current = true;
            handleAppCapsuleHeight();
        },[]);
        
        const fetchUserProfile = async () => { 
            const token = localStorage.getItem('token');     
            //console.log(apiCall);
            try {
              const response = await fetch('https://api.diosm.co.in/api/users/details', {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'token': token,
                },
                body: JSON.stringify({
                    userInfo: true,
                    ltln : '28.66977890,+77.34391700'
                }),
              });
              //console.log('Logout');
              if (response.status == '403') {
                localStorage.clear();
                navigate('/login');
              }
              if (!response.ok) {
                throw new Error('Failed to fetch user list');
              }        
              const res = await response.json();
              if (res.ERROR_CODE === '00') {
                layoutCotext.setProfileInfo(res.DATA);
                console.log(res.DATA);
              } else {
                layoutCotext.setToastMsg(res.MESSAGE);
                layoutCotext.setToastClass('bg-danger');
                layoutCotext.setToastShow(true);
                setTimeout(() => layoutCotext.setToastShow(false), 3000);
              }
            } catch (error) {
              console.error('Error fetching user profile:', error);
            }
          };
          
            
    const getDashboardData = async (endUrl,jsonBody) => {
      const token = localStorage.getItem('token');
      if(jsonBody.statFor != 'ALL'){
        layoutCotext.setToastMsg('Please wait..');
        layoutCotext.setToastShow('toastCenter');
      }
      
      try {
          const response = await fetch(endUrl, {
              method: 'POST',
              headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'token': token,
              },
              body: JSON.stringify(jsonBody),
          });

          if (!response.ok) {
              throw new Error('Failed to fetch dashboard data');
          }
          
          const res = await response.json();
          if(dashboardData.totalUsers !== undefined || dashboardData.todayTransaction !== undefined){
            const tu = res.totalUsers ? res.totalUsers : dashboardData.totalUsers;
            const combined = {
                ...dashboardData,
                ...res,
                totalUsers: tu // keep totalUsers from obj1
            };
            setDashboardData(combined);
            localStorage.setItem('dashboardData', JSON.stringify(combined)); 
            layoutCotext.setToastMsg('');
            layoutCotext.setToastShow(false);
          } else {
            setDashboardData(res);
            localStorage.setItem('dashboardData', JSON.stringify(res)); 
            layoutCotext.setToastMsg('');
            layoutCotext.setToastShow(false);
          }
          
                   
      } catch (error) {
          console.error('Error fetching dashboard data:', error);        
      }
    
    }
    const handleAppCapsuleHeight = () => {
      const appCapsule = document.querySelector('#appCapsule');
      const windowHeight = window.innerHeight;
      const appCapsuleHeight = appCapsule.clientHeight;
    
      console.log('App Capsule Height:', appCapsuleHeight);
      console.log('Window Height:', windowHeight);
    
      if (appCapsuleHeight < windowHeight) {
        const newHeight = windowHeight - appCapsuleHeight;
        const footerStyle = {'margin-top': newHeight};
        setAppFooterStyle(footerStyle);
        // appCapsule.style.height = `${newHeight}px`;
        // appCapsule.style.minHeight = `${windowHeight}px`;
      }
    };

        return (
            
            <>
                <div id="appCapsule" >
                        <WalletCard layoutCotext={layoutCotext} >
                            <WalletCardFotter userType={layoutCotext.profileInfo.userType}/>
                            <div></div>
                        </WalletCard>

                        <StatBox getDashboardData={getDashboardData} dashboardData={dashboardData} setDashboardData={setDashboardData} userType={layoutCotext.profileInfo.userType}/>
                        <br/>
      <div className="modal fade modalbox" id="ModalBasic" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">User Details</h5>
              <a href="#" data-bs-dismiss="modal">Close</a>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-sm test-small">
                  <thead>
                    <tr className="text-center">
                      <th scope="col" colSpan="2">{userDetails.usertype || ''}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">UserId</th>
                      <td className="text-end">{userDetails.user || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Name</th>
                      <td className="text-end">{userDetails.name || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile</th>
                      <td className="text-end">{userDetails.mobile || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td className="text-end">{userDetails.email || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td className="text-end">{userDetails.status || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Registered</th>
                      <td className="text-end">{userDetails.created_at || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Last Updated</th>
                      <td className="text-end">{userDetails.updated_at || ''}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-inline">
                <button className="btn btn-text-secondary" data-bs-dismiss="modal">CLOSE</button>
                <button className="btn btn-text-primary">SAVE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
                    
                    <div className="appFooter" style={appFooterStyle}>
                        <div className="footer-title">
                            Copyright © Diosm Fintect Pvt. Ltd. 2024. All Rights Reserved.
                        </div>
                    </div>

                </div>

            </>
        )
    
}

export default Home