import React, { Component, useEffect,useState } from 'react'
import { Outlet, useNavigate } from "react-router-dom";
import Header from './components/Header'
import Footer from './components/Footer'
import Nav from './components/Nav'
import LayoutContext from './contexts/layoutContext';

const Layout = () => {
    const [profileInfo,setProfileInfo] = useState({}); 
    const [toastClass,setToastClass] = useState('');
    const [toastShow,setToastShow] = useState('');
    const [toastMsg,setToastMsg] = useState('');

    const handleCloseToast = () => setToastShow(false);
        return (
            <LayoutContext.Provider value={{
                                            profileInfo,
                                            toastClass,
                                            toastShow,
                                            toastMsg,
                                            setProfileInfo,
                                            setToastClass,
                                            setToastShow,
                                            setToastMsg
                                        }}>
                <>
                    <Header />
                    <Outlet />
                    <Footer userType={profileInfo.userType} />
                    <Nav />
                    <div id="toast-16" className={`toast-box toast-top ${toastShow && toastShow == 'toast-16' ? 'show ' + toastClass : ''}`} style={{ 'top': toastShow && toastShow == 'toast-16' ? '55px' : undefined }}>
                        <div className="in">
                            <div className="text">
                                {toastMsg}
                            </div>
                        </div>
                        <button type="button" onClick={handleCloseToast} className="btn btn-sm btn-text-light close-button">OK</button>
                    </div>
                    <div id="toastCenter" className={`toast-box toast-center ${toastShow && toastShow == 'toastCenter' ? 'show ' : ''}`}>
                        <div className="in">
                            <div className="text">
                                {toastMsg}
                            </div>
                        </div>
                    </div>
                </>
            </LayoutContext.Provider>
        )
    
}

export default Layout