import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate  } from 'react-router-dom';

const UserList = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalResult, setTotalResult] = useState(0);
  const [resultShowing, setResultShowing] = useState('0 to 0');
  const [offsetStart, setOffsetStart] = useState(0);
  const [remainingData, setRemainingData] = useState(0);
  const [userType, setUserType] = useState('0');
  const [searchKey, setSearchKey] = useState('');
  const [userDetails, setUserDetails] = useState({}); // Changed to object
  

  const navigate = useNavigate();

  const isMounted = useRef(true);
  useEffect(() => {
    const token = localStorage.getItem('token'); 
    if (!token) {
      navigate('/login');
      console.log(token);
    }
    if (isMounted.current) {
      fetchUserList();
      isMounted.current = false;
    } else {
      if (offsetStart > 0) {
        fetchUserList(); 
      }
      if (offsetStart < 0) {
        setOffsetStart(0);
        fetchUserList(); 
      }
    }
    console.log(offsetStart);

  }, [offsetStart]); // Fetch user list when offsetStart changes

  const fetchUserList = async () => {
    const token = localStorage.getItem('token');
    console.log(offsetStart);
    const utFilter = userType !== '0' ? userType : '';
    try {
      const response = await fetch('https://api.diosm.co.in/api/users/list', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          length: 10, // Adjust as needed
          searchKey: searchKey,
          userType: utFilter,
          start: offsetStart,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user list');
      }

      const res = await response.json();
      const resultShowingText = res.to && res.to > 0 ? `1 to ${res.to}` : '0 to 0';

      res.data ? setUserList(prevList => [...prevList, ...res.data]) : setUserList(prevList => [...prevList]);
      setLoading(false);
      setTotalResult(res.total);
      setResultShowing(resultShowingText);
      setRemainingData(res.total - res.to);
    } catch (error) {
      console.error('Error fetching user list:', error);
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (remainingData > 0) {
     // isMounted.current = false;
      setOffsetStart(prevOffset => prevOffset + 10);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const viewUserDetail = (e) => {
    const userInfo = e.currentTarget.dataset.userinfo;
    setUserDetails(JSON.parse(userInfo));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'userType') setUserType(value);
    if (name === 'searchKey') setSearchKey(value);
  };

  const handleSubmit = () => {
    setUserList([]);
    //setLoading(true);
    setTotalResult(0);
    setResultShowing('0 to 0');
    setOffsetStart(-1); // Reset offset to start from beginning 
    setRemainingData(0);
    //isMounted.current = true;
    //fetchUserList();
  };

  return (
    <>
      <div className="appHeader">
        <div className="left">
          <button onClick={goBack} className="headerButton link">
            <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
            Back
          </button>
        </div>
        <div className="pageTitle">User List</div>
        <div className="right">
          <button className="headerButton link" data-bs-toggle="modal" data-bs-target="#searchActionSheet">
            <ion-icon name="search-outline"></ion-icon>
          </button>
        </div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-1 mb-2">
          <div className="section-title">Showing {resultShowing} of "<span className="text-primary">{totalResult} results</span>"</div>
          <div className="card">
            {/* User list rendering */}
            {loading ? (
              <div>Loading...</div>
            ) : (
              <ul className="listview image-listview media transparent flush">
                {userList.map(user => (
                  <li key={user.DT_RowId}>
                    <div className="item" onClick={viewUserDetail} data-bs-toggle="modal" data-bs-target="#ModalBasic" data-userinfo={JSON.stringify(user)}>
                      <div className="imageWrapper">
                        <ion-icon name="person-circle-outline"></ion-icon>
                      </div>
                      <div className="in">
                        <div>
                          {user.name}
                          <div className="text-xsmall">
                            <ion-icon name="bookmark-outline"></ion-icon> {user.user} &nbsp; &nbsp;
                            <ion-icon name="call-outline"></ion-icon> {user.mobile}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="section mb-2">
          <button disabled={remainingData <= 0} className="btn btn-block btn-secondary" onClick={loadMore}>
            {remainingData > 0 ? `Load More (Remaining ${remainingData})` : 'No More Data'}
          </button>
        </div>
      </div>

      <div className="modal fade action-sheet" id="searchActionSheet" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Filter</h5>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content">
                <form>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label">Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name / Mobile / Email"
                        onChange={handleInputChange}
                        name="searchKey"
                        value={searchKey}
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label">User Type</label>
                      <select
                        className="form-control custom-select"
                        onChange={handleInputChange}
                        name="userType"
                        value={userType}
                      >
                        <option value="0">All User</option>
                        <option value="RETAILER">Retailers</option>
                        <option value="DISTRIBUTOR">Distributors</option>
                        <option value="MDISTRIBUTOR">M.Distributors</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <button type="button" onClick={handleSubmit} className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modalbox" id="ModalBasic" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">User Details</h5>
              <a href="#" data-bs-dismiss="modal">Close</a>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-sm test-small">
                  <thead>
                    <tr className="text-center">
                      <th scope="col" colSpan="2">{userDetails.usertype || ''}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">UserId</th>
                      <td className="text-end">{userDetails.user || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Name</th>
                      <td className="text-end">{userDetails.name || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Mobile</th>
                      <td className="text-end">{userDetails.mobile || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Email</th>
                      <td className="text-end">{userDetails.meta_info?.email || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td className="text-end">{userDetails.status || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Registered</th>
                      <td className="text-end">{userDetails.create_date	 || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Shop Name</th>
                      <td className="text-end">{userDetails.meta_info?.shop_name || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Location</th>
                      <td className="text-end">{userDetails.meta_info?.city || ''}, {userDetails.meta_info?.state || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Location</th>
                      <td className="text-end">{userDetails.meta_info?.city || ''}, {userDetails.meta_info?.state || ''}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-inline">
                <button className="btn btn-text-secondary" data-bs-dismiss="modal">CLOSE</button>
                {/* <button className="btn btn-text-primary">SAVE</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default UserList;
