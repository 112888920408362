import React, { useState, useEffect } from 'react';

import Home from './pages/Home';
import Transactions from './pages/Transactions';
import UserList from './pages/UserList';
import Setting from './pages/Setting';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Layout from './Layout'
import AepsReports from './pages/reports/AepsReports';
import DmtReports from './pages/reports/DmtReports';
import BbpsReports from './pages/reports/BbpsReports';
import RechargeReports from './pages/reports/RechargeReports';
import ImpsReports from './pages/reports/ImpsReports';
import PayoutReports from './pages/reports/PayoutReports';
import AVReports from './pages/reports/AVReports';
import RetailerMainWallet from './pages/reports/RetailerMainWallet';
import RetailerTradeWallet from './pages/reports/RetailerTradeWallet';

import Comingsoon from './pages/Comingsoon';
//import AuthGuard from './components/common/AuthGaurd';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';

const App = () => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(true);

  useEffect(() => {
    // Load scripts only if they haven't been loaded yet
    if (!scriptsLoaded) {
      loadScripts();
    }
    // Set a timeout to simulate page loading
    const timer = setTimeout(() => {
      setPageLoaded(false);
    }, 1000); // 1000 milliseconds = 1 second (adjust as needed)

    // Clean up function
    return () => {
      // Remove scripts from the document when the component unmounts
      if (scripts) {
        scripts.forEach((script) => {
          document.body.removeChild(script);
        });
      }
      clearTimeout(timer); // Clear timeout on component unmount
    };
  }, [scriptsLoaded]); // Runs only when scriptsLoaded changes

  // Store the scripts in a variable to clean them up later
  let scripts = [];

  const loadScripts = () => {
    // Load Ionicons JS
    const ioniconsScript = document.createElement('script');
    ioniconsScript.type = 'module';
    ioniconsScript.src = 'https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js';
    document.body.appendChild(ioniconsScript);

    const ioniconsNomoduleScript = document.createElement('script');
    ioniconsNomoduleScript.setAttribute('noModule', ''); // Fix here
    ioniconsNomoduleScript.src = 'https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js';
    document.body.appendChild(ioniconsNomoduleScript);

    scripts = [
      ioniconsScript,
      ioniconsNomoduleScript
    ];
    setScriptsLoaded(true);
  };

  return (
    <>
      {pageLoaded && (
        <div id="loader">
          <img src="assets/img/loading-icon.png" alt="icon" className="loading-icon" />
        </div>
      )}
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />} >
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/dashboard" element={<Home />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/settings" element={<Setting />} />
            <Route path="/aeps-transactions" element={<AepsReports />} />
            <Route path="/dmt-transactions" element={<DmtReports />} />
            <Route path="/imps-transactions" element={<ImpsReports />} />
            <Route path="/payout-transactions" element={<PayoutReports />} />
            <Route path="/av-transactions" element={<AVReports />} />
            <Route path="/bbps-transactions" element={<BbpsReports />} />
            <Route path="/recharge-transactions" element={<RechargeReports />} />
            <Route path="/retailer-mwallet-report" element={<RetailerMainWallet />} />
            <Route path="/retailer-twallet-report" element={<RetailerTradeWallet />} />
            <Route path="/distributor-wallet-report" element={<Comingsoon />} />
            <Route path="/master-wallet-report" element={<Comingsoon />} />
            <Route path="/support" element={<Comingsoon />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
