import React from 'react'
import {Link} from 'react-router-dom'

const Transactions = () => {
    const userType = localStorage.getItem('userType');
        return (
            <>
                <div id="appCapsule">
                    <div className="listview-title mt-1">Service (Transactions)</div>
                    <ul className="listview image-listview inset mb-2">
                        <li>
                            <Link to="/aeps-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    AEPS
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/recharge-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Recharge
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/bbps-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    BBPS
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/dmt-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    DMT
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/imps-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    IMPS Transfer
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/payout-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Payout
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/av-transactions" className="item">
                                <div className="icon-box bg-primary">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Account Verificaton
                                </div>
                            </Link>
                        </li>
                        
                    </ul>
                    <div className="listview-title">Wallet Reports</div>
                    <ul className="listview image-listview inset mb-2">
                        <li>
                            <Link to="/retailer-mwallet-report" className="item">
                                <div className="icon-box bg-info">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                {(userType != 'RETAILER') ? 'Retailer ' : '' }Main Wallet
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/retailer-twallet-report" className="item">
                                <div className="icon-box bg-info">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    {(userType != 'RETAILER') ? 'Retailer ' : '' }Trade Wallet
                                </div>
                            </Link>
                        </li>
                        { userType && (userType == 'DISTRIBUTOR' || userType == 'MDISTRIBUTOR') ? (
                        <li>
                            <Link to="/distributor-wallet-report" className="item">
                                <div className="icon-box bg-info">
                                    <ion-icon name="cube-outline"></ion-icon>
                                </div>
                                <div className="in">
                                    Distributor Wallet
                                </div>
                            </Link>
                        </li>
                        ) : ('')}
                    </ul>                    
                </div>
            </>
        )

}

export default Transactions;