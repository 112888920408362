import React, { useState, useEffect, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import StatusIndicator from '../../components/StatusIndicator';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import AutoSuggestInput from '../../components/common/AutoSuggestInput';

export default function BbpsReports() {
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalResult, setTotalResult] = useState(0);
  const [status, setStatus] = useState('');
  const [filterUserId, setFilterUserId] = useState('');
  const [filterUserName, setFilterUserName] = useState('');
  const [resultShowing, setResultShowing] = useState('0 to 0');
  const [offsetStart, setOffsetStart] = useState(0);
  const [remainingData, setRemainingData] = useState(0);
  const [searchKey, setSearchKey] = useState('');
  const [transDetails, setTransDetails] = useState({});
  const [allChildUser, setAllChildUser] = useState({});
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'));

  const isMounted = useRef(true);
  const userType = localStorage.getItem('userType');
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      console.log(token);
    }
    if (isMounted.current) {
      if(userType!='RETAILER'){
        fetchChildUsers(token);
      }
      fetchReports();
      isMounted.current = false;
    } else {
      if (offsetStart > 0) {
        fetchReports();
      }
      if (offsetStart < 0) {
        setOffsetStart(0);
        fetchReports();
      }
    }
    console.log(offsetStart);

  }, [offsetStart]); // Fetch user list when offsetStart changes

  const fetchReports = async () => {
    const token = localStorage.getItem('token');
    //console.log(offsetStart);

    try {
      const response = await fetch('https://api.diosm.co.in/api/transaction/bbps', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          length: 10, // Adjust as needed
          searchKey: searchKey,
          status: status,
          transType: 'AV',
          start: offsetStart,
          startDate: moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DD'),
          endDate: moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DD'),
          userId: filterUserId
          //startDate: startDate,
          //endDate: endDate,
        }),
      });

      if (response.status == 403) {
        //throw new Error('Session Expired!');
        navigate('/logout');
      }

      if (!response.ok) {
        throw new Error('Failed to fetch list');
      }

      const res = await response.json();
      const resultShowingText = res.to && res.to > 0 ? `1 to ${res.to}` : '0 to 0';

      res.data ? setReportData(prevList => [...prevList, ...res.data]) : setReportData(prevList => [...prevList]);
      setLoading(false);
      setTotalResult(res.total);
      setResultShowing(resultShowingText);
      setRemainingData(res.total - res.to);
    } catch (error) {
      console.error('Error fetching list:', error);
      setLoading(false);
    }
  };

  const loadMore = () => {
    if (remainingData > 0) {
      // isMounted.current = false;
      setOffsetStart(prevOffset => prevOffset + 10);
    }
  };

  const fetchChildUsers = async (token) => {
    try {
      const response = await fetch('https://api.diosm.co.in/api/users/childUser', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'token': token,
        },
        body: JSON.stringify({
          userType: 'RETAILER'
        }),
      });

      if (response.status == 403) {
        navigate('/logout');
      }

      if (!response.ok) {
        throw new Error('Failed to fetch list');
      }
      const res = await response.json();
      setAllChildUser(res);
    } catch (error) {
      console.error('Error fetching list:', error);
    }
  }

  const goBack = () => {
    navigate(-1);
  };

  const viewUserDetail = (e) => {
    const transInfo = e.currentTarget.dataset.transinfo;
    //console.log(transInfo);
    setTransDetails(JSON.parse(transInfo));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'searchKey') setSearchKey(value);
    if (name === 'status') setStatus(value);
  };

  const handleSearchChange = (value) => {
    //console.log(Object.keys(value).length);
    setFilterUserId(value.DT_RowId);
    setFilterUserName(value.name);    
  }

  const handleSubmit = () => {
    setReportData([]);
    //setLoading(true);
    setTotalResult(0);
    setResultShowing('0 to 0');
    setOffsetStart(-1); // Reset offset to start from beginning 
    setRemainingData(0);
    //setStatus('');
    if(filterUserId === undefined){
      setFilterUserName('');
    }
  };

  const handleReset = () => {
    setReportData([]);
    //setLoading(true);
    setTotalResult(0);
    setResultShowing('0 to 0');
    setOffsetStart(-1); // Reset offset to start from beginning 
    setRemainingData(0);
    setStatus('');
    setSearchKey('');
    setStartDate(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'));
    setEndDate(moment().tz('Asia/Kolkata').format('YYYY-MM-DD'));
    setFilterUserId('');
    setFilterUserName('');
  };

  const tranType = (type) => {
    switch (type) {
      case 'CW':
        return 'Cash Withdrawal';
      case 'MS':
        return 'Mini Statement';
      case 'BE':
        return 'Balance Enquiry';
      default:
        return '';
    }
  }

  return (
    <>
      <div className="appHeader">
        <div className="left">
          <button onClick={goBack} className="headerButton link">
            <ion-icon name="chevron-back-outline" role="img" className="md hydrated" aria-label="chevron back outline"></ion-icon>
            Back
          </button>
        </div>
        <div className="pageTitle">BBPS Transactions</div>
        <div className="right">
          <button className="headerButton link" data-bs-toggle="modal" data-bs-target="#searchActionSheet">
            <ion-icon name="search-outline"></ion-icon>
          </button>
        </div>
      </div>

      <div id="appCapsule" className="extra-header-active full-height">
        <div className="section mt-1 mb-2">
          <div className="section-title">Showing {resultShowing} of "<span className="text-primary">{totalResult} results</span>"</div>
          <div className="card">
            {loading ? (
              <div>Loading...</div>
            ) : (
              <ul className="listview image-listview media transparent flush">
                {reportData.map(items => (
                  <li key={`${items.DT_RowId}-${items.agent_trid}`}>
                    <div className="item" onClick={viewUserDetail} data-bs-toggle="modal" data-bs-target="#ModalBasic" data-transinfo={JSON.stringify(items)}>
                      <div className="in">
                        <div>
                          {items.agent_trid}
                          <div className="text-xsmall">
                          {items.category}:- {items.date_created}
                          </div>
                        </div>
                        <div className="right">
                          <div className="price"> ₹ {items.amount ? items.amount : '0.00'} </div>
                          <StatusIndicator className="text-xsmall" status={items.status} />
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="section mb-2">
          <button disabled={remainingData <= 0} className="btn btn-block btn-secondary" onClick={loadMore}>
            {remainingData > 0 ? `Load More (Remaining ${remainingData})` : 'No More Data'}
          </button>
        </div>
      </div>

      <div className="modal fade action-sheet" id="searchActionSheet" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Filter</h5>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content">
                <form>
                  <div className="form-group basic dateFilter">
                    <div className="input-wrapper row">
                      <label className="label">Select Date</label>
                      {/* <input
                      type="text"
                      className="form-control"
                      placeholder="Transaction Id / Agent Trid / RRN / Aaadhar No. "
                      onChange={handleInputChange}
                      name="searchKey"
                      value={searchKey}
                    /> */}
                      <div className="col-6">
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                      <div className="col-6">
                        <DatePicker
                          className="form-control"
                          selected={endDate}
                          onChange={(date) => setEndDate(date)}
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label">Search</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Transaction Id / Agent Trid / Mobile  "
                        onChange={handleInputChange}
                        name="searchKey"
                        value={searchKey}
                      />
                      <i className="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>
                  <div className="form-group basic">
                    <div className="input-wrapper">
                      <label className="label">Status</label>
                      <select
                        className="form-control custom-select"
                        onChange={handleInputChange}
                        name="status"
                        value={status}
                      >
                        <option value="">All Trans.</option>
                        <option  value="SUCCESS">Success</option>
                        <option  value="FAILED">Failed</option>
                        <option  value="PENDING">Pending</option>
                      </select>
                    </div>
                  </div>
                  {(userType && userType!='RETAILER') ? (
                  <div className='form-group basic'>
                    <label className="label">Select Retailer</label>
                    <AutoSuggestInput className="form-control" placeholder="Type Retailer Name" list={allChildUser ? allChildUser : {}} onChange={handleSearchChange} name="retailerId" value={filterUserName} />
                  </div>
                  ) : ('')}

                  <div className="form-group basic text-center">

                    <button type="button" onClick={handleSubmit} className="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">
                      Search
                    </button>
                    <span style={{ 'cursor': 'pointer', 'marginTop': '10px', 'display': 'inline-block' }} onClick={handleReset}>Reset</span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade modalbox" id="ModalBasic" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Transaction Details</h5>
              <a href="#" data-bs-dismiss="modal">Close</a>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table table-sm test-small">
                  <thead>
                    <tr className="text-center">
                      <th scope="col" colSpan="2">Bill Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Amount</th>
                      <td className="text-end">₹ {transDetails.amount || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      <td className="text-end"><StatusIndicator status={transDetails.status || ''} /></td>
                    </tr>
                    <tr>
                      <th scope="row">Agent Trid</th>
                      <td className="text-end">{transDetails.agent_trid || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Transaction Id</th>
                      <td className="text-end">{transDetails.transaction_id || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">RRN</th>
                      <td className="text-end">{transDetails.rrn || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">K.No / C. No.</th>
                      <td className="text-end">{transDetails.param1 || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Customer Number</th>
                      <td className="text-end">{transDetails.customer_number || ''}</td>
                    </tr>
                    <tr>
                      <th scope="row">Trans. Date</th>
                      <td className="text-end">{transDetails.date_created || ''}</td>
                      {/* <td className="text-end">{transDetails.meta_info?.email || ''}</td> */}
                    </tr>
                    <tr>
                      <th scope="row">Category</th>
                      <td className="text-end">{transDetails.category || ''}</td>
                    </tr>                    
                    <tr>
                      <th scope="row">Message</th>
                      <td className="text-end">{transDetails.message || ''}</td>
                    </tr>                    
                    <tr>
                      <th scope="row">Operator Name</th>
                      <td className="text-end">{transDetails.billerName || ''}</td>
                    </tr>                    
                    {(transDetails.userId || transDetails.user?.user) && (
                      <tr>
                        <th scope="row">Retailer Id:</th>
                        <td className="text-end">{transDetails.userId || transDetails.user?.user || ''}</td>
                      </tr>
                    )}
                    {(transDetails.name || transDetails.user?.name) && (
                      <tr>
                        <th scope="row">Retailer Name:</th>
                        <td className="text-end">{transDetails.name || transDetails.user?.name || ''}</td>
                      </tr>
                    )}                    

                  </tbody>
                </table>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-inline">
                <button className="btn btn-text-secondary" data-bs-dismiss="modal">CLOSE</button>
                {/* <button className="btn btn-text-primary">SAVE</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
