import React from 'react'

export default function StatusIndicator({className, status}) {
    const getStatusColor = (status) => {
        switch (status) {
            case 'SUCCESS' : 
                return 'green';
            case 'FAILED' :
                return 'red';
            case 'PENDING' :
                return 'blue';
            default :
                return 'black';
        }
    }
  return (
    <div className={className} style={{'textAlign':'right', 'color' : getStatusColor(status) }}>
                        {status}
    </div>
  )
}
