import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <> 
      <div className="appHeader bg-primary text-light">
        <div className="left">
          <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
            <ion-icon name="menu-outline"></ion-icon>
          </a>
        </div>
        <div className="pageTitle">
          <img src="assets/img/logo.png" alt="logo" className="logo" />
        </div>
        <div className="right">
          <Link to="/" className="headerButton">
            <ion-icon className="icon" name="notifications-outline"></ion-icon>
            <span className="badge badge-danger">4</span>
          </Link>
          <Link to="/settings" className="headerButton">
            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="imaged w32" />
            <span className="badge badge-danger">6</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
